import { Button, styled } from "@mui/material";

/**
 * `DarkGreenButton` es un componente de botón estilizado utilizando Material-UI.
 * Este botón tiene un estilo predeterminado con un color de fondo verde oscuro,
 * borde verde oscuro, y una sombra. Se puede personalizar el ancho, alto, tamaño de fuente, 
 * y el padding a través de las propiedades.
 * 
 * @param {Object} props - Las propiedades que se pueden pasar al botón.
 * @param {string} [props.width="60%"] - El ancho del botón.
 * @param {string} [props.height="66px"] - La altura del botón.
 * @param {number|string} [props.fontSize=19] - El tamaño de la fuente del texto dentro del botón.
 * @param {string} [props.padding="10px 20px"] - El padding interno del botón.
 * 
 * @returns {JSX.Element} Un botón estilizado con el color verde oscuro.
 * 
 * @example
 * // Ejemplo de uso del DarkGreenButton
 * <DarkGreenButton width="100%" height="50px" fontSize={16} padding="12px 24px">
 *   Click Me
 * </DarkGreenButton>
 */
export const DarkGreenButton = styled(Button)(
  ({ width, height, fontSize, padding }) => ({
    justifyContent: "center",
    overflow: "hidden",
    alignItems: "center",
    padding: padding || "10px 20px",
    gap: "10px",
    width: width || "60%",
    fontFamily: "Lexend",
    textTransform: "none",
    fontWeight: 500,
    height: height || "66px",
    fontSize: fontSize || 19,
    border: "5px solid #65B231",
    backgroundColor: "#65B231",
    color: "#fff",
    borderRadius: 10,
    boxShadow: "10px 10px 37px 0px rgba(101, 179, 46, 0.25)",
    "&:hover": {
      border: "5px solid #65B231",
      backgroundColor: "#65B231",
      color: "#fff",
      fontFamily: "Lexend",
      textTransform: "none",
      fontWeight: 500,
    },
  })
);
