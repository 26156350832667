import React from "react";
import { Paper } from "@mui/material";

/**
 * CustomCard component that wraps children components inside a Paper.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the card.
 * @param {string|number} [props.width] - The width of the card, either in percentage or pixels.
 * @param {string|number} [props.margin] - The margin of the card, either in percentage or pixels.
 * @param {string|number} [props.alignItems] - The alignItems of the card, either in percentage or pixels.
 * @param {string|number} [props.border] - The border style of the card.
 * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | false} [props.maxWidth] - The maximum width of the Card.
 * @param {boolean} [props.blur] - Whether to apply a blur effect to the card.
 * @returns {JSX.Element} The rendered CustomCard component.
 */
const CustomCard = ({ children, width, padding, blur,border,margin,alignItems,boxShadow}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: width ? width : "80%",
        padding: padding ? padding : 4,
        margin: margin ? margin : 5,
        display: "flex",
        flexDirection: "column",
        alignItems: alignItems ? alignItems : "center",
        borderRadius: 5,
        backgroundColor: blur ? "rgba(255, 255, 255, 0.4)" : "white",
        backdropFilter: blur ? "blur(10px)" : "none",
        border: border ? border : "none",
        boxShadow: boxShadow ? boxShadow : "8px 8px 22px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      {children}
    </Paper>
  );
};

export default CustomCard;
