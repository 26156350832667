/**
 * Validates an email address against a robust regular expression.
 *
 * @param {string} [email=''] - The email address to validate. If not provided, defaults to an empty string.
 * @param {string} [error='It\'s not a valid email.'] - The error message to throw if the email is invalid.
 * @throws {Error} Throws an error with the provided message if the email is not valid.
 * @returns {boolean} Returns `true` if the email is valid.
 *
 * @example
 * try {
 *   validateEmail('test@example.com'); // Returns true
 * } catch (e) {
 *   console.error(e.message); // If the email is invalid
 * }
 *
 * @example
 * validateEmail('invalid-email'); // Throws an error with the message "It's not a valid email."
 */
export default (email = '', error = 'It\'s not a valid email.') => {
  const validRegex = 
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!String(email).toLowerCase().match(validRegex)) {
    throw new Error(error);
  }

  return true;
};
