import React, { useRef, useState } from "react";
import {
  Grid,
  Divider,
  Box,
  dividerClasses,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import CustomCard from "../Components/CustomCard";
import CustomText from "../Components/CustomText";
import { DarkGreenButton } from "../CSS/Contained/DarkGreenButton";
import CustomField from "../Components/CustomField";
import CustomButton from "../Components/CustomButton";
import validateIsEmail from "../Utils/validateIsEmail";
import { serverAPI } from "../APIs/servidor";

const JoabBoard = ({ phonewidth, xxlwidth }) => {
  /**
   * Navegación de la aplicación.
   */
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    DatosPersonales: {
      nombre: null,
      correo: null,
      telefono: null,
      sexo: null,
      tipoSangre: null,
      fechaNacimiento: null,
      CURP: null,
      RFC: null,
      NSS: null,
      direccion: null,
    },
    DatosProfesionales: {
      nivelEstudios: null,
      licenciatura: null,
      centroEstudios: null,
      cedula: null,
    },
    archivos: [],
    perfil: null,
    area: null,
    origen: "web",
    fechaAlta: new Date(),
    observaciones: null,
    responsable: null,
    estatus: "Nueva solicitud",
  });

  const handleButtonClick = () => {
    const input = document.getElementById("file").files[0];
    // setDoc(input);
    setForm({
      ...form,
      archivos: {
        archivos: input,
      },
    });
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "archive") {
      setForm((prevValues) => ({
        ...prevValues,
        archivos: { ...prevValues.archivos, cv: files[0] },
      }));
    } else {
      setForm((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  // Función para validar número de teléfono
  const validatePhone = (phone) => {
    return phone.length === 11;
  };

  const handleSubmit = async () => {
    console.log('form',form)
    const { correo, telefono } = form.DatosPersonales;

    if (!validateIsEmail(correo)) {
      enqueueSnackbar("Correo electrónico no válido.", { variant: "error" });
      return;
    }

    if (!validatePhone(telefono)) {
      enqueueSnackbar("El teléfono debe tener 11 dígitos.", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await serverAPI.post(
        "/administrador/addRegisterVacant",
        {
          body: JSON.stringify(form),
        }
      );

      if (!response.ok) {
        throw new Error("Error al enviar los datos.");
      }

      const data = await response.json();
      enqueueSnackbar("Solicitud enviada con éxito.", { variant: "success" });
      navigate("/gracias");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      enqueueSnackbar("Error al enviar los datos.", { variant: "error" });
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>MoviCare - Bolsa de trabajo</title>
        <meta
          name="description"
          content="¡Únete a nuestro equipo! Y forma parte de la mejor plataforma de salud en México."
        />
        <meta
          name="keywords"
          content="JoabBoarde,solicitud,empleo,unirse,equipo,médico,flebotomista,enfermera,programación"
        />
        <link rel="canonical" href="http://movicaremx.com/" />
        {/* <!-- CUADRO DE ENLACES DE BÚSQUEDA --> */}
        <script type="application/ld+json">
          {` {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://movicaremx.com",
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://movicaremx.com/?q={search_term_string}"
                },
                "query-input": "required name=search_term_string"
              }
           }`}
        </script>
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          backgroundColor: "#F8FCFF",
          marginTop: phonewidth ? 10 : 10,
        }}
      >
        <Grid item xs={12} md={12} xl={12}>
          <CustomText
            variant="h1"
            textAlign="center"
            align="center"
            justifyContent="center"
            sx={{
              ...design.title,
              fontSize: phonewidth ? 24 : xxlwidth ? 45 : 36,
            }}
          >
            Vacantes disponibles
          </CustomText>
        </Grid>
        <CustomCard
          margin={phonewidth ? "12% 0% 8% 2%" : "1% 5% 8% 5%"}
          alignItems="center"
          width={phonewidth ? "80%" : "100%"}
          border="1px solid #356C2DCC"
        >
          <CustomText
            variant="h2"
            textAlign="center"
            align="center"
            justifyContent="center"
            sx={{
              ...design.subtitle,
              fontSize: phonewidth ? 18 : xxlwidth ? 22 : 20,
              marginY: phonewidth && "1%",
            }}
          >
            Titulo de la vacante
          </CustomText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginY: phonewidth && "2%",
              "& svg": {
                m: 1,
              },
              [`& .${dividerClasses.root}`]: {
                mx: 0.5,
              },
            }}
          >
            <CustomText
              variant="body2"
              textAlign="left"
              align="left"
              justifyContent="left"
              sx={{
                ...design.info,
                fontSize: phonewidth ? 12 : xxlwidth ? 22 : 20,
              }}
            >
              Ubicación.
            </CustomText>
            <Divider orientation="vertical" flexItem />
            <CustomText
              variant="body2"
              textAlign="left"
              align="left"
              justifyContent="left"
              sx={{
                ...design.info,
                fontSize: phonewidth ? 12 : xxlwidth ? 22 : 20,
              }}
            >
              Escolaridad.
            </CustomText>
            <Divider orientation="vertical" flexItem />
            <CustomText
              variant="body2"
              textAlign="left"
              align="left"
              justifyContent="left"
              sx={{
                ...design.info,
                fontSize: phonewidth ? 12 : xxlwidth ? 22 : 20,
              }}
            >
              Tiempo.
            </CustomText>
          </Box>
          <CustomText
            variant="body2"
            textAlign="left"
            align="left"
            justifyContent="left"
            sx={{
              ...design.txt,
              fontSize: phonewidth ? 12 : xxlwidth ? 22 : 20,
              marginY: phonewidth && "2%",
            }}
          >
            Descripcion de la vacante, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </CustomText>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CustomText
              variant="body2"
              textAlign="left"
              align="left"
              justifyContent="left"
              sx={{
                ...design.secondary,
                fontSize: phonewidth ? 12 : xxlwidth ? 22 : 20,
                width: "100%",
              }}
            >
              Ver más
            </CustomText>
            <DarkGreenButton
              variant="contained"
              color="primary"
              width={phonewidth ? "100%" : "90%"}
              height={phonewidth ? "36px" : "56px"}
              fontSize={phonewidth ? 11 : 18}
              //   onClick={() => handleClick(item)}
            >
              <p>Postularme</p>
            </DarkGreenButton>
          </Stack>
        </CustomCard>
        <Grid item xs={12} md={1} xl={1} />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          xs={12}
          md={10}
          xl={10}
          spacing={2}
        >
          <CustomText
            variant="h3"
            textAlign="center"
            align="center"
            justifyContent="center"
            sx={{
              ...design.title,
              fontSize: phonewidth ? 24 : xxlwidth ? 45 : 36,
            }}
          >
            Llena tus datos para enviar tu solicitud
          </CustomText>
          <CustomField
            required
            grid={12}
            gridXS={12}
            width="100%"
            id="Nombre completo"
            label="Nombre completo"
            name="DatosPersonales.nombre"
            autoComplete="nombre"
            type="text"
            autoFocus
            value={form.DatosPersonales.nombre}
            onChange={handleChange}
          />
          <Grid
            container
            xs={12}
            md={6}
            xl={6}
            alignItems="center"
            justifyContent="center"
          >
            <FormControl sx={{ marginTop: 1 }}>
              <FormLabel id="gender-row-radio-buttons-label">
                Género biológico *
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="gender-row-radio-buttons-group-label"
                name="gender-row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Femenino"
                  control={<Radio />}
                  label="Femenino"
                />
                <FormControlLabel
                  value="Masculino"
                  control={<Radio />}
                  label="Masculino"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <CustomField
            type="date"
            name="DatosPersonales.fechaNacimiento"
            label="Fecha de nacimiento"
            value={form.DatosPersonales.fechaNacimiento}
            onChange={handleChange}
            required
            grid={6}
            gridXS={12}
            width="100%"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CustomField
            required
            width="100%"
            id="Teléfono"
            label="Teléfono"
            name="DatosPersonales.telefono"
            autoComplete="phone"
            type="number"
            autoFocus
            grid={6}
            gridXS={12}
            value={form.DatosPersonales.telefono}
            onChange={handleChange}
          />
          <CustomField
            required
            width="100%"
            id="Correo electrónico"
            label="Correo electrónico"
            name="DatosPersonales.correo"
            autoComplete="email"
            type="email"
            autoFocus
            grid={6}
            gridXS={12}
            value={form.DatosPersonales.correo}
            onChange={handleChange}
          />
          <Grid item xs={12} md={12} xl={12}>
            <CustomText
              variant="h4"
              textAlign="center"
              align="center"
              justifyContent="center"
              sx={{
                ...design.secondary,
                fontSize: phonewidth ? 24 : xxlwidth ? 45 : 36,
                marginY: phonewidth ? "1%" : 3,
              }}
            >
              Datos Profesionales
            </CustomText>
          </Grid>
          <CustomField
            required
            width="100%"
            id="Nivel de estudios"
            label="Nivel de estudios"
            name="DatosProfesionales.nivelEstudios"
            type="text"
            autoFocus
            value={form.DatosProfesionales.nivelEstudios}
            onChange={handleChange}
            grid={6}
            gridXS={12}
          />
          <CustomField
            required
            width="100%"
            id="Centro de estudios"
            label="Centro de estudios"
            name="DatosProfesionales.centroEstudios"
            type="text"
            autoFocus
            value={form.DatosProfesionales.centroEstudios}
            onChange={handleChange}
            grid={6}
            gridXS={12}
          />
          <CustomField
            width="100%"
            id="Licenciatura"
            label="Licenciatura"
            name="DatosProfesionales.licenciatura"
            type="text"
            autoFocus
            value={form.DatosProfesionales.licenciatura}
            onChange={handleChange}
            grid={6}
            gridXS={12}
          />
          <Grid item xs={12} md={6} xl={6}>
            <CustomButton
              width="75%"
              borderColor="#65B32E"
              colortext="#65B32E"
              backgroundcolorhover="#FFFFFF"
              bordercolor="rgba(101, 179, 46, 0.25)"
              fontSize={19}
              type="file"
              name="archive"
              label="Archivo"
              variant="outlined"
              onClick={handleButtonClick}
              sx={{ marginY: phonewidth ? "6%" : "2%" }}
            >
              Añade tu C.V.
            </CustomButton>
          </Grid>
          <CustomButton
            width="75%"
            backgroundcolor="#4496D2"
            colortexthover="#4496D2"
            backgroundcolorhover="#FFFFFF"
            colortexto="#FFFFFF"
            bordercolor="rgba(0, 81, 140, 0.50)"
            fontSize={19}
            type="file"
            name="archive"
            label="Archivo"
            variant="contained"
            onClick={handleSubmit}
            sx={{ marginY: phonewidth ? "6%" : "2%" }}
          >
            Postularme
          </CustomButton>
        </Grid>
        <Grid item xs={12} md={1} xl={1} />
      </Grid>
    </>
  );
};
export default JoabBoard;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    fontWeight: "700",
    color: "#00518c",
    marginY: "1%",
  },
  subtitle: {
    fontFamily: "Lexend",
    fontWeight: "400",
    color: "#093046",
  },
  line: {
    border: "2px solid #00528C40",
    marginY: "3%",
  },
  info: {
    fontFamily: "Lexend",
    fontWeight: "450",
    color: "#BEBEBE",
  },
  txt: {
    fontFamily: "Lexend",
    fontWeight: "300",
    color: "#00518c",
  },
  secondary: {
    fontFamily: "Lexend",
    fontWeight: "300",
    color: "#356c2d",
    textAlign: "center",
  },
};
