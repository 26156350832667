import React, { useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
  Stack,
} from "@mui/material";
import {
  Menu,
  Instagram,
  WhatsApp,
  LinkedIn,
  FacebookOutlined,
  ArrowBackIos,
} from "@mui/icons-material/";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

// Ancho del cajón lateral en píxeles
const drawerWidth = "100%";

/**
 * A button component for social media links.
 * Renders a button with the social media icon and tracks clicks using Google Analytics.
 * @param {Object} props - The component props.
 * @param {Object} props.social - The social media object.
 * @param {string} props.social.name - The name of the social media platform.
 * @param {string} props.social.url - The URL to the social media platform.
 * @param {JSX.Element} props.social.icon - The icon representing the social media platform.
 * @returns {JSX.Element} A button that links to the specified social media platform.
 * @function
 */
const SocialButton = ({ social }) => (
  <Button
    key={social.name}
    name={social.name}
    title={social.name}
    variant="text"
    rel="nofollow"
    target="_blank"
    href={social.url}
    onClick={() => {
      ReactGA.event({
        category: "Interaccion",
        action: `Visit a ${social.name}`,
        label: social.name,
        value: 1,
      });
    }}
  >
    {social.icon}
  </Button>
);

const Navbar = (props) => {
  const { window, phonewidth } = props;
  /**
   * Function to navigate between routes.
   * @type {Function}
   */
  const navigate = useNavigate();

  /**
   * State variable to control mobile menu visibility.
   * @type {[boolean, Function]}
   */
  const [mobileOpen, setMobileOpen] = useState(false);

  /**
   * State variable to control change visibility icon.
   * @type {[boolean, Function]}
   */
  const [loading, setLoading] = useState(false);

  /**
   * State variable to keep track of the currently active button.
   * @type {[number|null, Function]}
   */
  const [activeButton, setActiveButton] = useState(null);

  /**
   * Toggles the state of the mobile drawer.
   * This function inverts the current state of `mobileOpen`.
   *
   * @function
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /**
   * Handles the click event for navigation items.
   * Updates the active button state, tracks the event with Google Analytics,
   * and navigates to the specified URL. Also toggles the mobile drawer state.
   *
   * @param {Object} item - The item associated with the click event.
   * @param {string} item.name - The name of the item.
   * @param {string} item.url - The URL to navigate to.
   *
   * @function
   */
  const handleClickPages = (item) => {
    setActiveButton(item.name);
    ReactGA.event({
      category: "Interaccion",
      action: `Visit a ${item.name}`,
      label: item.name,
      value: 1,
      nonInteraction: true,
    });
    navigate(item.url);
    setMobileOpen(!mobileOpen);
  };

  /**
   * Handles the click event for the help button in the navbar.
   * Tracks the event with Google Analytics and opens a help link in a new tab.
   *
   * @function
   */
  const handleClickHelp = () => {
    setLoading(true);
    ReactGA.event({
      category: "Interaccion",
      action: "¿Necesitas ayuda? - navbar",
      label: "¿Necesitas ayuda?",
      value: 1,
    });
  };

  /**
   * Handles the click event for the download app button in the navbar.
   * Tracks the event with Google Analytics and opens a download link in a new tab.
   *
   * @function
   */
  const handleClickDownloadApp = () => {
    ReactGA.event({
      category: "Interaccion",
      action: "¡Descarga ya!-navbar",
      label: "¡Descarga ya!",
      value: 1,
    });
  };
  /**
   * The container for the drawer. Determines where the drawer should be attached in the DOM.
   *
   * @constant
   * @type {function|undefined}
   */
  const container =
    window !== undefined ? () => window().document.body : undefined;

  /**
   * The drawer component containing navigation items, social buttons, and custom action buttons.
   * It is structured using Material-UI components such as Stack, Box, List, and CustomButton.
   *
   * @constant
   * @type {JSX.Element}
   */
  const drawer = (
    <>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={12}
      >
        <Button onClick={handleDrawerToggle}>
          <ArrowBackIos sx={design.icon} />
        </Button>
        <Box
          onClick={handleDrawerToggle}
          component="img"
          alt="Movicare_logo"
          src="https://movicaremx.com/IMG/BACKGROUNDS/Movicare_favicon.png"
          width={50}
          height={50}
        />
      </Stack>

      <Box sx={{ textAlign: "center" }}>
        <List>
          {navItems.map((item, idx) => (
            <ListItem key={idx} disablePadding>
              <ListItemButton onClick={() => handleClickPages(item)}>
                <ListItemText
                  primaryTypographyProps={design.txt}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {sociales.map((social) => (
          <SocialButton key={social.name} social={social} />
        ))}
        <CustomButton
          width="75%"
          backgroundcolor="#4496D2"
          colortexthover="#4496D2"
          backgroundcolorhover="#FFFFFF"
          colortexto="#FFFFFF"
          bordercolor="rgba(0, 81, 140, 0.50)"
          type="submit"
          fontSize={19}
          onClick={handleClickHelp}
          // ¡Hola MoviCare! ¿Podrían ayudarme a cotizar un servicio con ustedes? Por favor ☺️ 🌐
          href="https://wa.link/dgvzl7"
          variant="contained"
        >
          ¿Necesitas ayuda?{" "}
          {loading ? (
            <WhatsApp fontSize="medium" sx={design.icon} />
          ) : (
            <WhatsApp fontSize="medium" style={design.iconWhite} />
          )}
        </CustomButton>
        <CustomButton
          width="75%"
          backgroundcolor="#4496D2"
          colortexthover="#4496D2"
          backgroundcolorhover="#FFFFFF"
          colortexto="#FFFFFF"
          bordercolor="rgba(0, 81, 140, 0.50)"
          fontSize={19}
          type="submit"
          href="https://uqr.to/s8ju"
          variant="contained"
          onClick={handleClickDownloadApp}
        >
          ¡Descarga ya!{" "}
          <Box
            component="img"
            alt="Movicare_logo"
            src="https://movicaremx.com/IMG/Sitioweb/Favicon_white.png"
            width={32}
            height={26}
          />
        </CustomButton>
      </Box>
    </>
  );

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        sx={{ backgroundColor: "#F8FCFF" }}
      >
        <Toolbar sx={design.toolContain}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu sx={design.icon} />
          </IconButton>
          <Box
            onClick={() => navigate("/")}
            component="img"
            alt="Movicare_servicios_médicos_logo"
            src="https://movicaremx.com/IMG/BACKGROUNDS/PNG/Movicare-logo-color.png"
            width={phonewidth ? "20%" : "10%"}
            height={phonewidth ? "6%" : "3%"}
            paddingY={1}
          />
          <Box sx={design.navContainer}>
            {navItems.map((item, index) => (
              <Button
                key={`${item.name}-${index}`}
                sx={{
                  ...design.txt,
                  fontSize: 20,
                  border:
                    activeButton === item.name
                      ? "2px solid #00528C"
                      : "2px solid transparent",
                  borderRadius: 3,
                  boxSizing: "border-box",
                }}
                onClick={() => handleClickPages(item)}
              >
                {item.name}
              </Button>
            ))}
            {sociales.map((social, index) => (
              <SocialButton key={`${social.name}-${index}`} social={social} />
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          sx={design.drawerContain}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Navbar;

/**
 * design es un objeto que contiene estilos personalizados para el texto dentro del tooltip.
 * @type {Object}
 */
const design = {
  icon: {
    color: "#00528C",
    fontWeight: "bold",
  },
  toolContain: {
    justifyContent: "space-between",
    marginRight: "3%",
  },
  drawerContain: {
    display: { xs: "block", sm: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
      backgroundImage:
        "url(https://movicaremx.com/IMG/Sitioweb/ondas_blue.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundOrigin: "padding-box",
    },
  },
  navContainer: {
    display: {
      xs: "none",
      sm: "block",
      marginLeft: "-10%",
      maxWidth: "95%",
    },
  },
  iconWhite: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  txt: {
    fontFamily: "Lexend",
    color: "#00528C",
    fontWeight: 400,
    textAlign: "center",
    textDecoration: "none",
    textTransform: "none",
  },
};

// Elementos de navegación en el cajón lateral
const navItems = [
  { name: "Inicio", url: "/" },
  { name: "Nosotros", url: "/nosotros" },
  { name: "Blog", url: "/Blog" },
  {
    name: "MoviLabs",
    url: "/Servicios-medicos-para-laboratorios-en-ciudad-de-mexico-y-area-metropolitana",
  },
  {
    name: "MoviEmpresas",
    url: "/Servicios-medicos-para-empresas-en-ciudad-de-mexico-y-area-metropolitana",
  },
];

// Enlaces a plataformas sociales
const sociales = [
  {
    icon: <FacebookOutlined fontSize="medium" style={design.icon} />,
    name: "Facebook",
    url: "https://www.facebook.com/MoviCareMx/",
  },
  {
    icon: <Instagram fontSize="medium" style={design.icon} />,
    name: "Instagram",
    url: "https://www.instagram.com/movicare_mx/?hl=es-la",
  },
  {
    icon: <LinkedIn fontSize="medium" style={design.icon} />,
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/movicaremx/",
  },
];
